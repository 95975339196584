import React, {useCallback, useContext, useState} from 'react';
import Row from 'react-bootstrap/Row';
import {useNavigate, useLocation} from 'react-router-dom';

import DBNavigationContext from '../context/DBNavigationContext';
import LightboxContext from '../context/LightboxContext';
import SessionContext from '../context/session';
import {replaceParams} from '../lib/path';

import AuthorizedRoutes from './AuthorizedRoutes';
import Lightbox from './Lightbox';
import MainNavDrawer from './MainNavDrawer';

const REDIRECT_MAP = new Map([
  ['/wo/print.php', '/app/work-orders/:woID']
]);

export default function AuthenticatedView() {
  const {user} = useContext(SessionContext);
  const navigate = useNavigate();
  const {pathname, search} = useLocation();
  const [lightboxImages, setLightboxImages] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const userParams = Object.freeze({id: user.id, userID: user.userID});

  const handleOnNavigation = useCallback(function _handleOnNavigation(messageEvent) {
    const {data} = messageEvent;
    const url = new URL(data);
    const {pathname: dbPathname, search: dbSearch} = url;
    const key = `${dbPathname}${dbSearch}`;
    const redirect = REDIRECT_MAP.get(key) || REDIRECT_MAP.get(dbPathname);
    const dbPath = `/app/db${key}`;
    if (redirect) {
      messageEvent.preventDefault();
      const replacementParams = {...userParams};
      for (const [searchKey, searchValue] of url.searchParams.entries()) {
        replacementParams[searchKey] = searchValue;
      }

      const replacementUrl = replaceParams(redirect, replacementParams);
      navigate(replacementUrl);
    } else if (`${pathname}${search}` !== dbPath) {
      navigate(dbPath, {replace: true});
    }
  }, [userParams, navigate, pathname, search]);

  return (
    <DBNavigationContext.Provider value={{onNavigation: handleOnNavigation}}>
      <LightboxContext.Provider value={{setLightboxImages, setActiveIndex}}>
        <Row className="h-100 d-flex">
          <MainNavDrawer/>
          <AuthorizedRoutes/>
        </Row>
        {lightboxImages?.length && (
          <Lightbox images={lightboxImages.map(li => li?.uri || li)} activeIndex={activeIndex}
                    onClose={() => {
                      setLightboxImages(null);
                      setActiveIndex(0);
                    }}/>
        )}
      </LightboxContext.Provider>
    </DBNavigationContext.Provider>
  );
}
