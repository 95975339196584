import React, {useMemo} from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import useTimer from '../hooks/useTimer';

import QuerySpinner from './QuerySpinner';
import SortableTable from './SortableTable';
import OvertimeReportRow from './OvertimeReportRow';

const HEADERS = [
  {key: 'payrollProcessingFrom', children: 'Processing From', sortable: false},
  {key: 'payrollProcessingTo', children: 'Processing To', sortable: false},
  {key: 'workWeeksFrom', children: 'Work From', sortable: false},
  {key: 'workWeeksTo', children: 'Work To', sortable: false},
  {key: 'overtimeCount', children: 'Overtime', sortable: false},
  {key: 'uri', children: null, sortable: false}
];

export default function OvertimeTable({overtimeReports, loading, ...props}) {
  const [alarm] = useTimer(loading);

  const tableBody = useMemo(() =>
    overtimeReports?.map((overtimeReport) => {
      const {payrollProcessingFrom} = overtimeReport;
      return (
        <OvertimeReportRow key={`${payrollProcessingFrom}`} overtimeReport={overtimeReport}/>
      );
    }), [overtimeReports]);

  if (alarm) {
    return (
      <Row className="h-100">
        <Col sm={12}>
          <QuerySpinner/>
        </Col>
      </Row>
    );
  }

  return (
    <Row className="overflow-auto flex-shrink-1 flex-grow-1" style={{minHeight: '33vh'}}>
      <SortableTable headers={HEADERS} body={tableBody} {...props}></SortableTable>
    </Row>
  );
}

export function overtimeOrderBy(columnDirectionMap) {
  return Array.from(columnDirectionMap.entries())
              .reduce((reduction, [attribute, direction]) => {
                reduction.push({attribute, direction});
                return reduction;
              }, []);
}