export default class User {
  constructor(userInfo) {
    Object.assign(this, userInfo);

    this.can = this.can.bind(this);
  }

  findRights({type, field = null}) {
    return this.role.rights
               .filter(r => r.type === type && (!r.field || r.field === field));
  }

  can(right, withoutContext) {
    const relevantRights = this.findRights(right);
    let result = !!relevantRights.length;
    if (withoutContext) {
      result = !!relevantRights.find(({context}) => !context);
    }

    return result;
  }

  //rights queries
  canQueryOverlappingTimes() {
    return this.can({type: 'Query', field: 'overlappingTimesConnection'});
  }

  canQueryAllPurchases() {
    return this.can({type: 'Query', field: 'purchasesConnection'}, true);
  }

  canQueryOtherThanOwnPurchases() {
    return !!this.findRights({type: 'Query', field: 'purchasesConnection'})
                 .find(({context}) => !context || context !== 'canQueryBySelf');
  }

  canViewPurchaseStats() {
    return this.can({type: 'PurchaseStats'});
  }

  canOnlyQueryOwnPurchases() {
    return !!this.findRights({type: 'Query', field: 'purchasesConnection'})
                 .find(({context}) => context === 'canQueryBySelf');
  }

  canGetVendor() {
    return this.can({type: 'Query', field: 'getVendor'});
  }

  canSetEarliestPurchaseDate() {
    return this.can({type: 'Mutation', field: 'setEarliestPurchaseDate'});
  }

  canUpdateAllPurchases() {
    return this.can({type: 'Mutation', field: 'updatePurchase'}, true);
  }

  canUpdatePurchase(purchase, locked) {
    if (!purchase) {
      return false;
    }
    const {userType, userID} = this;
    return this.canUpdateAllPurchases()
      || (
        !locked
        && userType === 'tech'
        && userID === purchase.technician.id
      );
  }

  canDeleteAnyPurchase() {
    return this.can({type: 'Mutation', field: 'deletePurchase'}, true);
  }

  canDeletePurchase(purchase, locked) {
    if (!purchase) {
      return true;
    }
    const {userType, userID} = this;
    return this.canDeleteAnyPurchase()
      || (
        !locked
        && userType === 'tech'
        && userID === purchase.technician.id
      );
  }

  canUpdatePurchaseTech() {
    return this.role.rights.find(({type, field, context}) =>
      'Mutation' === type
      && (!field || ['updatePurchase', 'addPurchase'].includes(field))
      && (!context || ['canAddForManagedJobs', 'canUpdateForManagedJobs'].includes(context))
    );
  }

  canQuerySchools() {
    return this.can({type: 'Query', field: 'schools'});
  }

  canViewAllJobs() {
    return this.can({type: 'Job'}, true);
  }

  canQueryTechnicians() {
    return this.can({type: 'Query', field: 'technicians'});
  }

  canQueryAllJobs() {
    return this.can({type: 'Query', field: 'jobsConnection'}, true);
  }

  canOnlyQueryJobsByOwnSchool() {
    return !!this.findRights({type: 'Query', field: 'jobsConnection'})
                 .find(({context}) => context === 'canQueryByOwnSchool');
  }

  canMutateAllPurchases() {
    return this.can({type: 'Mutate', field: 'updatePurchase'}, true);
  }

  canAddAllPurchases() {
    return this.can({type: 'Mutation', field: 'addPurchase'}, true);
  }

  canAssignTechToPurchaseForJob(job) {
    return this.canAddAllPurchases()
      || (job && !job.isSFMPurchaseAssignableJob && this.canOnlyAddPurchaseForManagedJobs());
  }

  canOnlyAddPurchaseForManagedJobs() {
    return !!this.findRights({type: 'Mutation', field: 'addPurchase'})
                 .find(({context}) => context === 'canAddForManagedJobs');
  }

  canOnlyAddForOwnSchoolWithCustPTAfterEPD() {
    return !!this.findRights({type: 'Mutation', field: 'addPurchase'})
                 .find(({context}) => context === 'canAddForOwnSchoolWithCustPTAfterEPD');
  }

  constrainWOToOwnSchool() {
    return !!this.findRights({type: 'Query', field: 'workOrdersConnection'})
                 .find(({context, constraint}) => context === 'constrainToOwnSchool' && constraint);
  }

  constrainWOToSelf() {
    return !!this.findRights({type: 'Query', field: 'workOrdersConnection'})
                 .find(({context, constraint}) => context === 'constrainToSelf' && constraint);
  }

  canQueryTasks() {
    return this.can({type: 'Query', field: 'tasks'});
  }

  canQueryComments() {
    return this.can({type: 'Query', field: 'workOrderComments'});
  }

  canQueryWorkOrderProgress() {
    return !this.findRights({type: 'Query', field: 'workOrdersConnection'})
                .find(({context, constraint}) => constraint && context === 'constrainToAnyProgress');
  }

//YELLOWTAG:TJH - not sure if I like checking for a role instead of rights,
  //   but I can't figure out an alternate solution
  isTechManager() {
    return 'mmcs_tech_mgr' === this.role.name;
  }

  canSplitPurchases() {
    return this.can({type: 'Mutation', field: 'splitPurchase'}, true);
  }

  canQueryLocations() {
    return this.can({type: 'Query', field: 'locationsConnection'});

  }

  canQueryAnyReport() {
    return this.canQueryLocations()
      || this.canQueryOverlappingTimes()
      || this.canQueryOvertimeReport();
  }

  canQueryTimeSheets() {
    return this.can({type: 'Query', field: 'utcTimeSheetsConnection'});
  }

  canQueryAllTimeSheets() {
    return this.can({type: 'Query', field: 'timeSheetsConnection'}, true);
  }

  canQueryOvertimeReport() {
    return this.can({type: 'Query', field: 'overtimeReportsConnection'});
  }
}
