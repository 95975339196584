import React from 'react';
import Container from 'react-bootstrap/Container';

import useColumnSort from '../../hooks/useColumnSort';
import useOvertimeReportSummaries from '../../hooks/useOvertimeReportSummaries';

import Header from './Header';
import OvertimeTable, {overtimeOrderBy} from '../../components/OvertimeTable';
import ConnectionPagination from '../../components/ConnectionPagination';

export default function OvertimeReports() {
  const {columnSortDirection, onSort} = useColumnSort(
    new Map([
      ['payrollProcessingFrom', 'DESC'],
      ['workWeeksFrom', 'DESC']
    ])
  );
  const {
    overtimeReportsConnection,
    overtimeReportLoading,
    pageSize,
    setPageSize
  } = useOvertimeReportSummaries(overtimeOrderBy(columnSortDirection));

  const {overtimeReports} = overtimeReportsConnection || {};

  return (
    <Container className="d-flex flex-column h-100" fluid>
      <Header/>
      <OvertimeTable overtimeReports={overtimeReports} loading={overtimeReportLoading}
                     sortDirection={columnSortDirection} onSort={onSort}/>
      {
        overtimeReportsConnection
        && (
          <ConnectionPagination connection={overtimeReportsConnection} pageSize={pageSize}
                                onChangePageSize={setPageSize}/>
        )
      }
    </Container>
  );
}