import {useCallback, useContext, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

import SessionContext from '../context/session';
import RouteContext from '../context/RouteContext';

import {EMPTY_QUERY, sterilizeQuery} from '../lib/filterUtils';

export default function useQueryContext(routeContextKey, query, setQuery, toUrl, baseQuery) {
  const {user} = useContext(SessionContext);
  const {getRouteContext, setRouteContext} = useContext(RouteContext);
  const location = useLocation();

  const routeContext = getRouteContext(routeContextKey);
  const sterilizedQuery = sterilizeQuery(user, query);

  let redirect;
  if (JSON.stringify(sterilizedQuery) === EMPTY_QUERY) {
    if (routeContext) {
      const baseQuery = {...(routeContext)};
      if (Object
        .keys(baseQuery)
        .includes('page')) {
        baseQuery.page = 1;
      }
      redirect = toUrl(baseQuery, location);
    }
  }

  useEffect(() => {
    if (routeContext || redirect) {
      return;
    }
    setRouteContext(routeContextKey, sterilizedQuery);
  }, [routeContext, redirect, sterilizedQuery, routeContextKey, setRouteContext]);

  const handleUpdateQuery = useCallback(function _handleUpdateQuery(updatedQuery) {
    const vQuery = sterilizeQuery(user, updatedQuery);
    setQuery(query => {
      const result = {...query, ...vQuery};
      setRouteContext(routeContextKey, result);
      if (Object
        .keys(query)
        .includes('page')) {
        result.page = 1;
      }
      return result;
    });
  }, [user, routeContextKey, setRouteContext, setQuery]);

  const handleClearQuery = useCallback(function _handleClearQuery() {
    setRouteContext(routeContextKey, baseQuery);
    setQuery(baseQuery);
  }, [routeContextKey, baseQuery, setRouteContext, setQuery]);

  return {query: sterilizedQuery, handleUpdateQuery, handleClearQuery, redirect};
}