import React, {useCallback, useContext, useMemo} from 'react';

import SessionContext from '../context/session';
import TimeSheetsContext from '../context/TimeSheetsContext';
import useTechnicians from '../hooks/useTechnicians';

import FilterPills from './FilterPills';
import TechnicianPills from './TechnicianPills';

export default function TimeSheetFilterPills() {
  const {user} = useContext(SessionContext);
  const {technicianIDs, handleUpdateQuery, handleClearQuery} = useContext(TimeSheetsContext);
  const {technicians} = useTechnicians(technicianIDs);

  const techsToDisplay = useMemo(() => {
    return technicians?.filter(({id}) => id !== user.userID);
  }, [technicians, user]);

  const handleRemoveTechnician = useCallback((techToRemove) => {
    let updatedQuery = {technicianIDs: technicianIDs.filter((id) => id !== techToRemove.id)};
    handleUpdateQuery(updatedQuery);
  }, [technicianIDs, handleUpdateQuery]);

  return (
    <FilterPills onClearQuery={handleClearQuery}>
      <TechnicianPills technicians={techsToDisplay} onRemoveTechnician={handleRemoveTechnician}/>
    </FilterPills>
  );
}