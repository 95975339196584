import React, {useMemo} from 'react';
import moment from 'moment';

import FormattedDate from './FormattedDate';

export default function OvertimeReportRow({overtimeReport}) {
  const {
    payrollProcessingFrom,
    workWeeksFrom,
    overtimeCount,
    uri
  } = overtimeReport;

  const {
    payrollProcessingTo,
    workWeeksTo
  } = useMemo(() => {
    const {
      payrollProcessingTo: originalPayrollProcessingTo,
      workWeeksTo: originalWorkWeeksTo
    } = overtimeReport;
    return {
      payrollProcessingTo: moment(originalPayrollProcessingTo)
        .subtract(1, 'day'),
      workWeeksTo: moment(originalWorkWeeksTo)
        .subtract(1, 'day')
    };
  }, [overtimeReport]);

  return (
    <tr>
      <td>
        <FormattedDate date={payrollProcessingFrom}/>
      </td>
      <td>
        <FormattedDate date={payrollProcessingTo}/>
      </td>
      <td>
        <FormattedDate date={workWeeksFrom}/>
      </td>
      <td>
        <FormattedDate date={workWeeksTo}/>
      </td>
      <td>{overtimeCount}</td>
      <td>
        {
          uri
          && (
            <a href={uri} target="_blank" rel="noreferrer">
              View Report
            </a>
          )
        }
      </td>
    </tr>
  );
}