import {useState} from 'react';
import {gql, useQuery} from '@apollo/client';

import usePagination from './usePagination';

const overtimeReportSummariesQuery = gql`
    query overtimeReportSummaries($pageSize: Int, $offset: Int, $orderBy: [OrderBy!])
    @connection(key: "overtimeReportSummaries", filter:[]){
        overtimeReportsConnection(pageSize: $pageSize, offset: $offset, orderBy: $orderBy){
            next
            hasMore
            total
            overtimeReports {
                payrollProcessingFrom
                payrollProcessingTo
                workWeeksFrom
                workWeeksTo
                overtimeCount
                uri
            }
        }
    }
`;

const PAGE_SIZE = 10;

export default function useOvertimeReportSummaries(orderBy = []) {
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const {offset} = usePagination(pageSize);

  const {data, loading, error, refetch} = useQuery(overtimeReportSummariesQuery, {
    variables: {pageSize, offset, orderBy},
    fetchPolicy: 'cache-and-network'
  });

  if(error && !(data && data.lenth)){
    throw error;
  }

  const {overtimeReportsConnection} = data || {};

  return {
    overtimeReportsConnection,
    overtimeReportLoading: loading,
    refetchOvertimeReports: refetch,
    pageSize,
    setPageSize
  };
}