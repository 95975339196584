import {
  NumericArrayParam,
  useQueryParams,
  encodeQueryParams,
  createEnumParam
} from 'use-query-params';
import queryString from 'query-string';

import {Views} from 'react-big-calendar';

import {MomentParam} from './customQueryParams';

const ViewNameParam = createEnumParam(
  Object
    .keys(Views)
    .map(key => Views[key])
);
const PARAM_CONFIG_MAP = {
  technicianIDs: NumericArrayParam,
  view:ViewNameParam,
  date:MomentParam
};

export default function useTimeSheetQueryParams(){
  return useQueryParams(PARAM_CONFIG_MAP);
}

export function toUrl(query, {pathname}){
  const qs = queryString.stringify(encodeQueryParams(PARAM_CONFIG_MAP, query));
  return `${pathname}?${qs}`;
}

export const BASE_QUERY = Object.freeze({
  technicianIDs: undefined
})