import React from 'react';

import FilteredListHeader from '../../components/FilteredListHeader';
import TimeSheetFilterPills from '../../components/TimeSheetFilterPills';

export default function Header() {
  return (
    <FilteredListHeader title="Time Sheets"
                        FilterPills={<TimeSheetFilterPills/>}/>
  );
}
