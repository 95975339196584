import React, {useMemo} from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import useTimer from '../hooks/useTimer';
import OverlappingTimeTableRow from './OverlappingTimeTableRow';
import QuerySpinner from './QuerySpinner';
import SortableTable from './SortableTable';

const HEADERS = [
  {key: 'techType', children: 'Tech Type', sortable: false},
  {key: 'periodFrom', children: 'Period From', sortable: false},
  {key: 'periodTo', children: 'Period To', sortable: false},
  {key: 'overlaps', children: 'Overlaps', sortable: false},
  {key: 'uri', children: null, sortable: false}
];

export default function OverlappingTimeTable({overlappingTimes, loading, ...props}) {
  const [alarm] = useTimer(loading);

  const tableBody = useMemo(() =>
    overlappingTimes?.map(overlappingTime => {
      const {periodFrom, techType} = overlappingTime;
      return (
        <OverlappingTimeTableRow
          key={`${periodFrom}-${techType}`}
          overlappingTime={overlappingTime}
        />
      );
    }), [overlappingTimes]);

  if (alarm) {
    return (
      <Row className="h-100">
        <Col sm={12}>
          <QuerySpinner/>
        </Col>
      </Row>
    );
  }

  return (
    <Row className="overflow-auto flex-shrink-1 flex-grow-1" style={{minHeight: '33vh'}}>
      <SortableTable headers={HEADERS} body={tableBody} {...props} />
    </Row>
  );
}

export function overlappingTimeOrderBy(columnDirectionMap) {
  return Array.from(columnDirectionMap.entries())
              .reduce((reduction, [attribute, direction]) => {
                reduction.push({attribute, direction});
                return reduction;
              }, []);
}
