import React, {useContext} from 'react';
import {Navigate} from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';

import TimeSheetsHeader from './TimeSheetsHeader';
import SessionContext from '../../context/session';
import TimeSheetsContext from '../../context/TimeSheetsContext';

import useTimeSheetsContext from '../../hooks/useTimeSheetsContext';

import TimeSheetsContainer from './TimeSheetsContainer';

export default function TimeSheets() {
  const {user} = useContext(SessionContext);
  const timeSheetsContext = useTimeSheetsContext();

  if (!user?.userID) {
    return null;
  }

  const {redirect, popupPortalRef} = timeSheetsContext;

  if (redirect) {
    return (<Navigate to={redirect}/>);
  }

  const height = user.canQueryAllTimeSheets()
                 ? 75
                 : 95;
  return (
    <TimeSheetsContext.Provider value={timeSheetsContext}>
      <Alert className="m-3" variant="info">
        This is a working Beta version. If you have questions or concerns call Pat Millius:
        &nbsp;<a href="tel:615-715-6884">615-715-6884</a>
      </Alert>
      <Container ref={popupPortalRef} style={{height:`${height}%`}} className="d-flex flex-column m-3" fluid>
        {user.canQueryAllTimeSheets() && <TimeSheetsHeader/>}
        <TimeSheetsContainer/>
      </Container>
    </TimeSheetsContext.Provider>
  );
}