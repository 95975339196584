import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function Headder(){
  return(
    <Container className="flex-shrink-0" fluid>
      <Row className="pt-1 align-items-center bg-light">
        <Col>
          <h4 className="fw-bold">Overtime Reports</h4>
        </Col>
      </Row>
    </Container>
  )
}