import React, {useCallback, useContext, useMemo} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import SessionContext from '../../context/session';

import Container from 'react-bootstrap/Container';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import OverlappingTimes from '../../page/OverlappingTimes';
import Locations from '../../page/Locations';
import OvertimeReports from '../../page/OvertimeReports';

export default function Reports() {
  const {user} = useContext(SessionContext);
  const {activeTab} = useParams();
  const navigate = useNavigate();

  const defaultActiveKey = useMemo(() =>
    user.canQueryOverlappingTimes()
    ? 'overlapping-time'
    : 'locations', [user]);

  const handleSelectTab = useCallback((key) => {
    navigate(`/app/reports/${key}`);
  }, [navigate]);

  return (
    <Container fluid>
      <Tabs id="report-tabs" className="mb-3 mt-3" defaultActiveKey={defaultActiveKey} activeKey={activeTab}
            onSelect={handleSelectTab} mountOnEnter unmountOnExit>
        {
          user.canQueryOverlappingTimes()
          && (
            <Tab eventKey="overlapping-time" title="Overlapping Time">
              <OverlappingTimes/>
            </Tab>
          )
        }
        {
          user.canQueryLocations()
          && (
            <Tab eventKey="locations" title="Locations">
              <Locations/>
            </Tab>
          )
        }
        {
          user.canQueryOvertimeReport()
          && (
            <Tab eventKey="overtime-report" title="Overtime Report">
              <OvertimeReports/>
            </Tab>
          )
        }
      </Tabs>
    </Container>
  );
}