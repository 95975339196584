import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import moment from 'moment';
import _ from 'lodash';
import {Views} from 'react-big-calendar';

import SessionContext from '../context/session';

import useEarliestPayrollDate from './useEarliestPayrollDate';
import useLocalStorage from './useLocalStorage';
import useTimeSheetQueryParams, {toUrl, BASE_QUERY} from './useTimeSheetQueryParams';
import useQueryContext from './useQueryContext';

const ROUTE_CONTEXT_KEY = 'page.TimeSheets';

export default function useTimeSheetsContext() {
  const {user} = useContext(SessionContext);
  const [tsQuery, setTsQuery] = useTimeSheetQueryParams();
  const {redirect, query, handleUpdateQuery, handleClearQuery}
    = useQueryContext(ROUTE_CONTEXT_KEY, tsQuery, setTsQuery, toUrl, BASE_QUERY);

  const {earliestPayrollDate} = useEarliestPayrollDate();
  const [getDefaultSchool, setDefaultSchool] = useLocalStorage('defaultSchool');
  const [getDefaultJob, setDefaultJob] = useLocalStorage('defaultJob');
  const [selectedTimeSheet, setSelectedTimeSheet] = useState(null);
  const [timeSheetUnderEdit, setTimeSheetUnderEdit] = useState(null);
  const [timeSheetToDelete, setTimeSheetToDelete] = useState(null);
  const popupPortalRef = useRef(null);

  const defaultTechIDs = useMemo(() => [user.userID], [user.userID]);
  useEffect(() => {
    let {technicianIDs, date, view} = query;
    let updateQuery = false;
    if (user) {
      if (!technicianIDs?.length
        || (
          !user.canQueryAllTimeSheets()
          && (!_.isEqual(technicianIDs, defaultTechIDs))
        )) {
        technicianIDs = defaultTechIDs;
        updateQuery = true;
      }
    }
    if (!date) {
      date = moment();
      updateQuery = true;
    }
    if (!view) {
      view = Views.WEEK;
      updateQuery = true;
    }
    if (updateQuery) {
      handleUpdateQuery({technicianIDs, date, view});
    }
  }, [user, query, defaultTechIDs, handleUpdateQuery]);

  const setCalendarDate = useCallback((date) => {
    handleUpdateQuery({date});
  }, [handleUpdateQuery]);

  const setCalendarView = useCallback((view) => {
    handleUpdateQuery({view});
  }, [handleUpdateQuery]);

  const startDateTime = useMemo(() => {
    let {date, view} = query;
    if (view === 'agenda') {
      view = 'day';
    }
    return date
           ? moment(date).startOf(view || Views.WEEK)
           : null;
  }, [query]);

  const endDateTime = useMemo(() => {
    let {date, view} = query;
    let result;
    if (date && view === 'agenda') {
      result = moment(date)
        .add(1, 'month')
        .endOf('day');
    } else {
      result = date
               ? moment(date).endOf(view || Views.WEEK)
               : null;
    }
    return result;
  }, [query]);

  const calendarDate = useMemo(() => {
    const {date} = query;
    return date
           ? moment(date)
           : null;
  }, [query]);

  const {technicianIDs, view} = query;
  return {
    technicianIDs,
    startDateTime,
    endDateTime,
    calendarView: view, setCalendarView,
    calendarDate, setCalendarDate,
    earliestPayrollDate,
    getDefaultSchool, setDefaultSchool,
    getDefaultJob, setDefaultJob,
    selectedTimeSheet, setSelectedTimeSheet,
    timeSheetUnderEdit, setTimeSheetUnderEdit,
    timeSheetToDelete, setTimeSheetToDelete,
    popupPortalRef,
    redirect, query, handleUpdateQuery, handleClearQuery
  };
}